/*!
 * animateOnScroll.js
 */
(function () {
  const animatedEls = document.querySelectorAll(".js-animate");
  const isActive = "is-active";

  const intersectionCallback = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add(isActive);

        if (entry.target.hasAttribute("data-animation-delay")) {
          entry.target.style.transitionDelay = entry.target.getAttribute("data-animation-delay");
        }

        observer.unobserve(entry.target);
      }
    });
  };

  const options = {
    root: null,
    rootMargin: "0px 0px",
    threshold: window.innerWidth <= 576 ? 0.25 : 0.5
  };

  if (animatedEls && "IntersectionObserver" in window) {
    const observer = new IntersectionObserver(intersectionCallback, options);

    animatedEls.forEach((el) => {
      observer.observe(el);
    });
  } else {
    animatedEls.forEach((el) => {
      el.classList.add(isActive);
    });
  }
})();
