/*!
 * main.js
 */
document.addEventListener("DOMContentLoaded", () => {
  // Remove Preload class (prevent unwanted CSS transitions on load)
  document.documentElement.classList.remove("Preload");

  // Init MiniLazyload
  new MiniLazyload();

  const header = document.getElementById("js-header");

  function setStickyOffsets() {
    document.documentElement.style.setProperty("--stickyHeaderHeight", header.offsetHeight + "px");
  }

  setStickyOffsets();

  window.addEventListener("resize", () => {
    setStickyOffsets();
  });
});

// Toggle Preload class on resize (prevent unwanted CSS transitions on resize)
let resizeTimer;
window.addEventListener("resize", () => {
  document.documentElement.classList.add("Preload");
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(() => {
    document.documentElement.classList.remove("Preload");
  }, 400);
});
