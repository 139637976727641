/*!
 * injectYouTube.js
 */
(function () {
  const injectYouTubeEls = document.querySelectorAll(".js-injectYouTube");
  const isPlaying = "is-playing";

  const injectYouTube = (trigger) => {
    trigger.addEventListener("click", () => {
      const videoID = trigger.getAttribute("data-youtubevideo-id");
      const srcURL = `https://www.youtube.com/embed/${videoID}?autoplay=1`;
      const iframe = `<iframe src="${srcURL}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;

      let container = null;

      if (trigger.classList.contains("js-modal-open")) {
        // Inject YT video in Modal
        container = document.querySelector("#js-youTubeVideo-container");
      } else {
        container = trigger.parentElement.querySelector(".js-youTubeVideo-container");
      }

      container.insertAdjacentHTML("beforeend", iframe);

      container.parentElement.classList.add(isPlaying);
      // trigger.setAttribute("aria-hidden", true);
    });
  };

  if (injectYouTubeEls.length) {
    injectYouTubeEls.forEach((injectYouTubeEl) => {
      injectYouTube(injectYouTubeEl);
    });
  }
})();
