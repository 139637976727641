/*!
 * nav.js
 */
(function () {
  // Toggle nav
  const header = document.getElementById("js-header");
  const nav = document.getElementById("js-nav");
  const navLinks = nav.querySelectorAll(".Nav-link");
  const navButton = document.getElementById("js-nav-toggle");
  const navExtras = document.getElementById("js-nav-extras");
  const navExtrasContainer = document.getElementById("js-nav-extras-container");
  const isActive = "is-active";
  const isBlocked = "is-blocked";

  if (navExtras && navExtrasContainer) {
    const mqMobileNav = "(max-width: 800px)";
    const mqMobileNavMethod = window.matchMedia(mqMobileNav);

    const handleViewportChange = (ev) => {
      if (ev.media === mqMobileNav) {
        if (ev.matches) {
          nav.appendChild(navExtras);
        } else {
          navExtrasContainer.appendChild(navExtras);
        }
      }
    };

    mqMobileNavMethod.addEventListener("change", handleViewportChange);
    handleViewportChange(mqMobileNavMethod);
  }

  const inactiveNav = () => {
    // Fix Headroom bug on page with custom scrollbar
    header && header.classList.remove("is-pinned");

    nav.classList.remove(isActive);
    nav.style.top = "100%";
    nav.style.height = "auto";
    navButton.classList.remove(isActive);
    navButton.setAttribute("aria-expanded", false);
    document.body.classList.remove(isBlocked);
  };

  if (nav && navButton) {
    navButton.addEventListener("click", () => {
      // Fix Headroom bug on page with custom scrollbar
      header && header.classList.toggle("is-pinned");

      // Get top position relative to the viewport for calculate nav height
      const navOffsetTop = nav.getBoundingClientRect().top;
      nav.style.top = `${navOffsetTop}px`;
      nav.style.height = `calc(100% - ${navOffsetTop}px)`;

      nav.classList.toggle(isActive);
      navButton.classList.toggle(isActive);
      navButton.setAttribute("aria-expanded", nav.classList.contains(isActive));
      document.body.classList.toggle(isBlocked, nav.classList.contains(isActive));

      if (!nav.classList.contains(isActive)) {
        nav.style.top = "100%";
        nav.style.height = "auto";
      }
    });

    document.addEventListener("click", (ev) => {
      if (nav.classList.contains(isActive) && !nav.contains(ev.target) && !navButton.contains(ev.target)) {
        inactiveNav();
      }
    });

    document.addEventListener("keydown", (ev) => {
      if (ev.key === "Escape") {
        inactiveNav();
      }
    });

    navLinks.forEach((navLink) => {
      navLink.addEventListener("click", () => {
        navLinks.forEach((navLink) => {
          navLink.classList.remove(isActive);
        });

        navLink.classList.add(isActive);
        inactiveNav();
      });
    });

    window.addEventListener("resize", () => {
      inactiveNav();
    });
  }
})();
